import { CircularProgress, Container } from '@material-ui/core'
import React from 'react'
import commonStyles from 'src/styles/commonStyles'

export default function Loading() {
  const commonClasses = commonStyles()
  return (
    <Container component="main" className={commonClasses.alignCenter}>
      <CircularProgress />
    </Container>
  )
}
