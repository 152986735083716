import * as React from 'react'
import { navigate } from 'gatsby'
import { FirebaseContext } from 'src/utils/firebase'
import { useContext } from 'react'
import Loading from 'src/components/Molecules/Loading'

interface PrivateRouteProps {
  path: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, path }) => {
  const { isSessionLoaded, authToken } = useContext(FirebaseContext)

  // Prevent an exception on build time
  if (typeof window == 'undefined') {
    return null
  }

  if (!isSessionLoaded) {
    return <Loading />
  }

  if (!authToken && window?.location.href !== path) {
    navigate(path)
    return null
  }
  return <>{children}</>
}
export default PrivateRoute
